import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { NoIndex, description, title } from '@components/layout/SEO';
import { UpdatePasswordComplete } from '@features/reset-password/UpdatePasswordComplete';

const CompletePage: FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>パスワードを変更しました | {title}</title>
        <meta name="description" content={description} />
        {NoIndex}
      </Helmet>
      <UpdatePasswordComplete />
    </Layout>
  );
};

export default CompletePage;
