import { navigate } from 'gatsby';
import React, { FC } from 'react';

import { Button } from '@components/controls/button';
import { Center } from '@components/layout/center';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { useDetectMedia } from '@hooks/useDetectMedia';

export const UpdatePasswordComplete: FC = () => {
  const { md } = useDetectMedia();

  return (
    <Center>
      <VStack spacing="30px" textAlign="center">
        <Heading2 fontSize="24px" lh="40px">
          パスワードを変更しました
        </Heading2>
        <Flex direction={md ? 'row' : 'column'}>
          <Paragraph lh="17px" width="fit-content">
            パスワードの変更が完了しました。
          </Paragraph>
          <Paragraph lh="17px" width="fit-content">
            引き続きサービスをご利用ください。
          </Paragraph>
        </Flex>
        <Button
          radius="5px"
          width="196px"
          bg="gray2"
          border="1px solid #d9d9d9d9"
          onClick={() => navigate('/')}
        >
          TOPページへ戻る
        </Button>
      </VStack>
    </Center>
  );
};
